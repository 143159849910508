// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.imgbanner{

 /* The image used */
  background-color: #cccccc; /* Used if the image is unavailable */
  width: 100%;
  height: 500px; /* You must set a specified height */
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover;
  
}
.animate {
  animation-name: example;
  animation-duration: 500ms;
  animation-timing-function: linear;
  animation-delay: 2s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

@keyframes example {
  0% { opacity: 1; } /* Start with full opacity */
  100% { opacity: 0; } /* Gradually fade out to completely transparent */
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;;CAEC,mBAAmB;EAClB,yBAAyB,EAAE,qCAAqC;EAChE,WAAW;EACX,aAAa,EAAE,oCAAoC;EACnD,2BAA2B,EAAE,qBAAqB;EAClD,4BAA4B,EAAE,4BAA4B;EAC1D,sBAAsB;;AAExB;AACA;EACE,uBAAuB;EACvB,yBAAyB;EACzB,iCAAiC;EACjC,mBAAmB;EACnB,mCAAmC;EACnC,8BAA8B;AAChC;;AAEA;EACE,KAAK,UAAU,EAAE,EAAE,4BAA4B;EAC/C,OAAO,UAAU,EAAE,EAAE,iDAAiD;AACxE","sourcesContent":[".imgbanner{\n\n /* The image used */\n  background-color: #cccccc; /* Used if the image is unavailable */\n  width: 100%;\n  height: 500px; /* You must set a specified height */\n  background-position: center; /* Center the image */\n  background-repeat: no-repeat; /* Do not repeat the image */\n  background-size: cover;\n  \n}\n.animate {\n  animation-name: example;\n  animation-duration: 500ms;\n  animation-timing-function: linear;\n  animation-delay: 2s;\n  animation-iteration-count: infinite;\n  animation-direction: alternate;\n}\n\n@keyframes example {\n  0% { opacity: 1; } /* Start with full opacity */\n  100% { opacity: 0; } /* Gradually fade out to completely transparent */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
